export const DEFAULT_FOLLOWED_TAGS = [
  'art',
  'automotive',
  'blockchain',
  'comedy',
  'economics',
  'education',
  'gaming',
  'music',
  'news',
  'science',
  'sports',
  'technology',
];

export const DISABLE_COMMENTS_TAG = 'disable-comments';
export const DISABLE_SUPPORT_TAG = 'disable-support';
export const PREFERENCE_EMBED = 'preference-embed';

export const UTILITY_TAGS = [DISABLE_COMMENTS_TAG, DISABLE_SUPPORT_TAG, PREFERENCE_EMBED];

export const MATURE_TAGS = [
  'porn',
  'porno',
  'nsfw',
  'mature',
  'xxx',
  'sex',
  'creampie',
  'blowjob',
  'handjob',
  'vagina',
  'boobs',
  'big boobs',
  'big dick',
  'pussy',
  'cumshot',
  'anal',
  'hard fucking',
  'ass',
  'fuck',
  'hentai',
];

const DEFAULT_ENGLISH_KNOWN_TAGS = [
  'free speech',
  'censorship',
  'gaming',
  'pop culture',
  'entertainment',
  'technology',
  'music',
  'funny',
  'education',
  'learning',
  'news',
  'gameplay',
  'nature',
  'beliefs',
  'comedy',
  'games',
  'film & animation',
  'game',
  'weapons',
  'blockchain',
  'video game',
  'sports',
  'walkthrough',
  'lbrytvpaidbeta',
  'art',
  'pc',
  'minecraft',
  'playthrough',
  'economics',
  'automotive',
  'play',
  'tutorial',
  'twitch',
  'how to',
  'ps4',
  'bitcoin',
  'fortnite',
  'commentary',
  'lets play',
  'fun',
  'politics',
  'travel',
  'food',
  'science',
  'xbox',
  'liberal',
  'democrat',
  'progressive',
  'survival',
  'non-profits',
  'activism',
  'cryptocurrency',
  'playstation',
  'nintendo',
  'government',
  'steam',
  'podcast',
  'gamer',
  'horror',
  'conservative',
  'reaction',
  'trailer',
  'love',
  'cnn',
  'republican',
  'political',
  'hangoutsonair',
  'hoa',
  'msnbc',
  'cbs',
  'anime',
  'donald trump',
  'fiction',
  'fox news',
  'crypto',
  'ethereum',
  'call of duty',
  'android',
  'multiplayer',
  'epic',
  'rpg',
  'adventure',
  'secular talk',
  'btc',
  'atheist',
  'atheism',
  'video games',
  'ps3',
  'cod',
  'online',
  'agnostic',
  'movie',
  'fps',
  'lets',
  'mod',
  'world',
  'reviews',
  'sharefactory',
  'space',
  'pokemon',
  'stream',
  'hilarious',
  'lol',
  'sony',
  'god',
  'dance',
  'pvp',
  'tech',
  'strategy',
  'zombies',
  'fail',
  'film',
  'xbox360',
  'animation',
  'unboxing',
  'money',
  'wwe',
  'mods',
  'indie',
  'pubg',
  'ios',
  'history',
  'rap',
  'mobile',
  'trump',
  'hack',
  'flat earth',
  'trap',
  'humor',
  'vlogging',
  'fox',
  'news radio',
  'facebook',
  'edm',
  'fitness',
  'vaping',
  'hip hop',
  'secular',
  'jesus',
  'song',
  'vape',
  'guitar',
  'remix',
  'mining',
  'daily',
  'diy',
  'pets',
  'videogame',
  'death',
  'funny moments',
  'religion',
  'media',
  'viral',
  'war',
  'nbc',
  'freedom',
  'gold',
  'family',
  'meme',
  'zombie',
  'photography',
  'chill',
  'sniper',
  'computer',
  'iphone',
  'dragon',
  'bible',
  'pro',
  'overwatch',
  'litecoin',
  'gta',
  'house',
  'fire',
  'bass',
  'truth',
  'crash',
  'mario',
  'league of legends',
  'wii',
  'mmorpg',
  'health',
  'marvel',
  'racing',
  'apple',
  'instrumental',
  'earth',
  'destiny',
  'satire',
  'race',
  'training',
  'electronic',
  'boss',
  'roblox',
  'family friendly',
  'california',
  'react',
  'christian',
  'mmo',
  'twitter',
  'help',
  'star',
  'cars',
  'random',
  'top 10',
  'ninja',
  'guns',
  'linux',
  'lessons',
  'vegan',
  'future',
  'dota 2',
  'studio',
  'star wars',
  'shooting',
  'nasa',
  'rock',
  'league',
  'subscribe',
  'water',
  'gta v',
  'car',
  'samsung',
  'music video',
  'skyrim',
  'dog',
  'comics',
  'shooter game',
  'bo3',
  'halloween',
  'liberty',
  'eth',
  'conspiracy',
  'knife',
  'fashion',
  'stories',
  'vapor',
  'nvidia',
  'cute',
  'beat',
  'nintendo switch',
  'fantasy',
  'christmas',
  'world of warcraft',
  'industry',
  'cartoon',
  'garden',
  'animals',
  'windows',
  'happy',
  'magic',
  'memes',
  'design',
  'tactical',
  'fallout 4',
  'puzzle',
  'parody',
  'rv',
  'beats',
  'building',
  'disney',
  'drone',
  'ps2',
  'beach',
  'metal',
  'christianity',
  'business',
  'mix',
  'bo2',
  'cover',
  'senate',
  '4k',
  'united states',
  'final',
  'hero',
  'playing',
  'dlc',
  'ubisoft',
  'halo',
  'pc gaming',
  'raw',
  'investing',
  'online learning',
  'software',
  'ark',
  'mojang',
  'console',
  'battle royale',
  'canon',
  'microsoft',
  'camping',
  'ufo',
  'progressive talk',
  'switch',
  'fpv',
  'arcade',
  'school',
  'driving',
  'bodybuilding',
  'drama',
  'retro',
  'science fiction',
  'eggs',
  'australia',
  'modded',
  'rainbow',
  'gamers',
  'resident evil',
  'drawing',
  'brasil',
  'england',
  'hillary clinton',
  'singing',
  'final fantasy',
  'hiphop',
  'video blog',
  'mature',
  'quad',
  'noob',
  'simulation',
  'illuminati',
  'poetry',
  'dayz',
  'manga',
  'howto',
  'insane',
  'press',
  'special',
  'church',
  'ico',
  'weird',
  'libertarian',
  'crafting',
  'level',
  'comic',
  'sandbox',
  'daily vlog',
  'outdoor',
  'black ops',
  'sound',
  'christ',
  'duty',
  'juvenile fiction',
  'pc game',
  'how-to',
  'ww2',
  'creepy',
  'artist',
  'galaxy',
  'destiny 2',
  'new music',
  'quest',
  'lee',
  'pacman',
  'super smash bros',
  'day',
  'survival horror',
  'patreon',
  'bitcoin price',
  'trending',
  'open world',
  'wii u',
  'dope',
  'reaper',
  'sniping',
  'dubstep',
  'truck',
  'planet',
  'dc',
  'amazon',
  'spirituality',
  'universe',
  'video game culture',
  'community',
  'cat',
  'aliens',
  'tourism',
  'altcoins',
  'style',
  'travel trailer',
  'rda',
  'gun',
  'secret',
  'far cry 5',
  'auto',
  'culture',
  'dj',
  'mw2',
  'lord',
  'full time rving',
  'role-playing game',
  'prank',
  'grand theft auto',
  'master',
  'wrestling',
  'sci-fi',
  'workout',
  'ghost',
  'fake news',
  'silly',
  'season',
  'bo4',
  'trading',
  'extreme',
  'economy',
  'combat',
  'plays',
  'muslim',
  'pubg mobile',
  'clips',
  'bo1',
  'paypal',
  'sims',
  'exploration',
  'light',
  'ripple',
  'paranormal',
  'football',
  'capcom',
  'rta',
  'discord',
  'batman',
  'player',
  'server',
  'anarchy',
  'military',
  'playlist',
  'cosplay',
  'rv park',
  'rant',
  'edit',
  'germany',
  'reading',
  'chris',
  'flash',
  'loot',
  'bitcoin gratis',
  'game reviews',
  'movies',
  'stupid',
  'latest news',
  'squad gameplay',
  'guru',
  'timelapse',
  'black ops 3',
  'holiday',
  'soul',
  'motivation',
  'mw3',
  'vacation',
  'sega',
  '19th century',
  'pop',
  'sims 4',
  'post',
  'smok',
  'island',
  'scotland',
  'paladins',
  'warrior',
  'creepypasta',
  'role-playing',
  'solar',
  'vr',
  'animal',
  'peace',
  'consciousness',
  'dota',
  'audio',
  'mass effect',
  'humour',
  'first look',
  'videogames',
  'future bass',
  'freestyle',
  'hardcore',
  'portugal',
  'dantdm',
  'teaser',
  'lbry',
  'coronavirus',
  '2020protests',
  'covidcuts',
  'covid-19',
  'LBRYFoundationBoardCandidacy',
];

const DEFAULT_SPANISH_KNOWN_TAGS = [
  'español',
  'tecnología',
  'criptomonedas',
  'economía',
  'bitcoin',
  'educación',
  'videojuegos',
  'música',
  'noticias',
  'ciencia',
  'deportes',
  'latinoamérica',
  'latam',
  'conspiración',
  'humor',
  'política',
  'tutoriales',
];

export const DEFAULT_KNOWN_TAGS = [...DEFAULT_ENGLISH_KNOWN_TAGS, ...DEFAULT_SPANISH_KNOWN_TAGS];
